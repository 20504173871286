:root {
  --r: translate();
  --opc-ran: 0s;
  --arr-offset: 90%;
}

.square {
  background-color: #282c34;
  max-width: 100vw;
  max-height: 100vh;
  width: 100%;
  height: 100%;
  position: absolute;
  margin: auto;
  display: block;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  opacity: 1;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.LoadingSquare {
  background-color: #f1c40f;
  max-width: 0.75vw;
  max-height: 1.5vh;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: var(--r);
  z-index: -1;

  /* border: 1px solid black; */

  opacity: 0.4;

  animation: lSquareRot 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite;
  animation-play-state: running;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.moreAnim {
  opacity: 0;

  animation: lSquareOpc var(--opc-ran) cubic-bezier(0.25, 0.46, 0.45, 0.94) alternate infinite;
  animation-play-state: running;
}

.Kaiskii-Logo {
  max-width: 1000px;
  width: 100%;
  height: 100%;
  position: absolute;
  margin: auto;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-55%, -50%);
  opacity: 1;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.Arrow-Down {
  width: 3vw;
  height: auto;

  opacity: 0.4;

  position: fixed;
  top: var(--arr-offset);
  left: 50%;

  transform: translateX(-50%);

  animation: arrowOpc 1.2s alternate infinite;
  animation-play-state: running;
}

@keyframes arrowOpc {
  100% {
    opacity: 0;
  }
}

@keyframes lSquareOpc {
  100% {
    opacity: 1;
  }
}

@keyframes lSquareRot {
  100% {
      transform: var(--r) rotate(360deg);
  }
}