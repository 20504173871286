@media only screen and (pointer:none), (pointer:coarse) and (max-width: 768px) {
  .Navbar {
    justify-content: center;
    align-items: center;

    display: flex;
  }

  .Navbar-Btn {
    justify-content: center;
    align-items: center;
    display: flex;
    transform: scale(0.8);
  }
}


@media only screen and (pointer: fine) and (min-width: 1px) {
  .Navbar {
    justify-content: flex-end;
    align-items: center;

    display: flex;
  }
}

.Navbar {
  width: 100%;
  height: 10vh;
  top: 0px;
  background-color: #1e272e;
  opacity: 0.9;

  position: fixed;

  /* justify-content: flex-end; */
  align-items: center;

  display: flex;

  z-index: 10;

  box-shadow: 0px 8px 15px 5px;
}

.Navbar-Btn {
  background-color: none;

  height: auto;
  width: auto;

  min-width: 0;
  min-height: 0;
  max-width: 100%;
  max-height: 100%;
  /* overflow: hidden; */

  justify-content: flex-end;
  align-items:center;
  margin-right: 1.5vw;
  flex: 0 1 75px;

  display: flex;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-user-drag: none;
}

.SM-Btn:hover {
  box-shadow: 0px 0px 10px 5px black;
  border-radius:190px;

  transform: scale(1.1);
  transition: 0.2s;
}