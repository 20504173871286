html {
  scroll-behavior: smooth;
}

* {
  scrollbar-width: thin;
  scrollbar-color: rgba(223, 230, 233, 0.6) rgba(45, 52, 54, 1.0);
}

/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
  width: 14px;
}

*::-webkit-scrollbar-track {
  background: #2d3436;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(223, 230, 233, 0.6);
  border-radius: 6px;
  border: 3px solid #2d3436;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
