@media only screen and (max-width: 765px) {
  p {
    visibility: hidden;
  }
}

p {
  font-size: calc(0.6vw + 0.6vh);
}

h1 {
  font-size: calc(1.2vw + 1.2vh);
}

.Project-BG {
  width: 100%;
  height: 100%;

  top: 100%;

  max-width: 100vw;
  max-height: 100vh;

  background-image: url(./img/sub-grey.png);
  background-repeat: repeat;
  background-color: rgba(44, 62, 80, 1);

  background-repeat: repeat;
  display: block;
  position: absolute;
}

.Project-Slider-Container {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
}

.Project-Slider-Content {
  height: 100vh;

  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.Project-Image {
  width: 60vw;
  height: 60vh;

  top: 50%;
  left: 50%;

  transform: translate(-50%, -60%);

  box-shadow: 0px 0px 20px 6px;

  position: absolute;
}

.Project-Text {
  color: #f5f6fa;

  left: 50%;
  transform: translate(-50%, -50%);

  position: absolute;
  display: table-cell;
  text-align: justify;
}

.swiper-button-prev {
  color: #7f8c8d;
  filter: drop-shadow( 0px 4px 2px rgba(0, 0, 0, .7));
  transition: 250ms;
}

.swiper-button-next {
  color: #7f8c8d;
  filter: drop-shadow( 0px 4px 2px rgba(0, 0, 0, .7));
  transition: 250ms;
}

.swiper-button-prev:hover{
  transform: scale(1.1);
}

.swiper-button-next:hover{
  transform: scale(1.1);
}